<div class="modal-header">
    <fa-icon [icon]="faUser" role="icon"></fa-icon> <strong>PERSON</strong>
    <button nbButton shape="semi-round" status="primary" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ngx-app-person [person]="person" (person_saved$)="handlePersonSaved($event)" ></ngx-app-person>
</div>
<div class="modal-footer">
    <button nbButton shape="semi-round" status="primary" type="button" class="btn btn-outline-dark">Close</button>
</div>
