export class Person {

     number: number = 0;
     id: string = "";
     first_name: string = "";
     last_name: string = "";
     address_1: string = "";
     address_2: string = "";
     city: string = "";
     state: string = "";
     postal_code: string = "";
     country: string = "";
     phone: string = "";
     birth_date: Date | null = null;
     fax: string = "";
     email: string = "";
     ec_number: string = "";
     usef_number: string = "";
     ohja_number: string = "";
     ushja_number: string = "";
     category: string = "";
     stable: string = "";
     fei_id: string = "";

     constructor(values: Object = {}) { 
        Object.assign(this, values);
    }

}