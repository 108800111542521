import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {SessionService} from './session.service';
import {SmsApiSessionService} from './sms-api-session.service';
import {User} from '../models/user';

import {AppSettings} from '../app.settings';
import {Horse_show_organizer} from '../models/horse_show_organizer';
import {Horse_show} from '../models/horse_show';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    public username: string = 'USER NOT SET';

    constructor(private http: HttpClient, private sessionService: SessionService,
                private smsApiSessionService: SmsApiSessionService) {

        this.smsApiSessionService.getSession().then(user => {
            this.username = user.username;
        }).catch(error => {
            this.username = 'USER NOT SET';
        });

    }

    login(username: string, password: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.http.post(AppSettings.API_ENDPOINT + 'users/login', JSON.stringify({
                username: username,
                password: password,
            }), AppSettings.DEFAULT_HTTP_OPTIONS)
                .subscribe((response: any) => {
                    // login successful if there's a jwt token in the response
                    if (response.data && response.data.type === 'auth_object') {
                        const token = response.data.token;
                        // store username and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem(AppSettings.AUTH_TOKEN_NAME, JSON.stringify({
                            username: username,
                            token: token,
                        }));
                        this.username = username;
                        this.sessionService.setUser(response.data.user);
                        // return true to indicate successful login
                        resolve(true);
                    } else {
                        reject(false);
                    }
                }, error_response => {
                    if (error_response instanceof HttpErrorResponse) {
                        reject(false);
                    } else {
                        reject(false);
                    }
                });
        });
    }

    logout(): Promise<any> {
        return new Promise((resolve, reject) => {

            try {
                localStorage.removeItem(AppSettings.AUTH_TOKEN_NAME);
                this.sessionService.setUser(new User({}));
                this.sessionService.setHorse_show_organizer(new Horse_show_organizer({}));
                this.sessionService.setHorse_show(new Horse_show({}));
                resolve('User is logged out.');

            } catch (error) {
                reject(error);
            }
        });
    }

}
