<div class="container mt-5">
    <form [formGroup]="organizerForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="number">Number</label>
        <input type="number" id="number" class="form-control" formControlName="number">
      </div>
      <div class="form-group">
        <label for="id">ID</label>
        <input type="text" id="id" class="form-control" formControlName="id">
      </div>
      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" id="name" class="form-control" formControlName="name">
      </div>
      <div class="form-group">
        <label for="shortName">Short Name</label>
        <input type="text" id="shortName" class="form-control" formControlName="shortName">
      </div>
      <div class="form-group">
        <label for="paymentGateway">Payment Gateway</label>
        <input type="text" id="paymentGateway" class="form-control" formControlName="paymentGateway">
      </div>
      <div class="form-group">
        <label for="merchantAccountId">Merchant Account ID</label>
        <input type="text" id="merchantAccountId" class="form-control" formControlName="merchantAccountId">
      </div>
      <div class="form-group">
        <label for="contactEmail">Contact Email</label>
        <input type="email" id="contactEmail" class="form-control" formControlName="contactEmail">
      </div>
      <div class="form-group">
        <label for="convenienceFeeRate">Convenience Fee Rate</label>
        <input type="number" id="convenienceFeeRate" class="form-control" formControlName="convenienceFeeRate">
      </div>
      <div class="form-group">
        <label for="convenienceFeeWording">Convenience Fee Wording</label>
        <input type="text" id="convenienceFeeWording" class="form-control" formControlName="convenienceFeeWording">
      </div>
      <div class="form-group">
        <label for="convenienceFeeName">Convenience Fee Name</label>
        <input type="text" id="convenienceFeeName" class="form-control" formControlName="convenienceFeeName">
      </div>
      <div class="form-group form-check">
        <input type="checkbox" id="collectConvenienceFee" class="form-check-input" formControlName="collectConvenienceFee">
        <label for="collectConvenienceFee" class="form-check-label">Collect Convenience Fee</label>
      </div>
      <div class="form-group">
        <label for="phone">Phone</label>
        <input type="text" id="phone" class="form-control" formControlName="phone">
      </div>
      <div class="form-group">
        <label for="url">URL</label>
        <input type="text" id="url" class="form-control" formControlName="url">
      </div>
      <div class="form-group">
        <label for="merchant_descriptor_name">Merchant Descriptor Name</label>
        <input type="text" id="merchant_descriptor_name" class="form-control" formControlName="merchant_descriptor_name">
      </div>
      <div class="form-group">
        <label for="primary_country_iso_3">Primary Country ISO 3</label>
        <input type="text" id="primary_country_iso_3" class="form-control" formControlName="primary_country_iso_3">
      </div>
      <div class="form-group">
        <label for="address">Address</label>
        <input type="text" id="address" class="form-control" formControlName="address">
      </div>
      <div class="form-group">
        <label for="city">City</label>
        <input type="text" id="city" class="form-control" formControlName="city">
      </div>
      <div class="form-group">
        <label for="state">State</label>
        <input type="text" id="state" class="form-control" formControlName="state">
      </div>
      <div class="form-group">
        <label for="postal_code">Postal Code</label>
        <input type="text" id="postal_code" class="form-control" formControlName="postal_code">
      </div>
      <div class="form-group">
        <label for="country">Country</label>
        <input type="text" id="country" class="form-control" formControlName="country">
      </div>
      <button nbButton shape="semi-round" status="primary" type="submit" class="btn btn-primary mt-3">Submit</button>
    </form>
  </div>
