<ngx-app-person-search (personSelected$)="handlePersonSelected($event)"></ngx-app-person-search>
<form #personForm="ngForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-6">
    <div class="form-floating mb-3">
      <input type="text" (change)="handleChange('first_name', _person.first_name)" class="form-control" id="first_name"
        name="first_name" [(ngModel)]="_person.first_name" placeholder="First Name">
      <label for="first_name">First Name</label>
    </div>
    </div>
    <div class="col-6">
    <div class="form-floating mb-3">
      <input type="text" (change)="handleChange('last_name', _person.last_name)" class="form-control" id="last_name"
        name="last_name" [(ngModel)]="_person.last_name" placeholder="Last Name" required>
      <label for="last_name">Last Name</label>
    </div>
  </div>
  </div>
  <div class="form-floating mb-3">
    <input type="text" (change)="handleChange('address_1', _person.address_1)" class="form-control" id="address_1"
      name="address_1" [(ngModel)]="_person.address_1" placeholder="Address 1">
    <label for="address_1">Address 1</label>
  </div>
  <div class="form-floating mb-3">
    <input type="text" (change)="handleChange('address_2', _person.address_2)" class="form-control" id="address_2"
      name="address_2" [(ngModel)]="_person.address_2" placeholder="Address 2">
    <label for="address_2">Address 2</label>
  </div>
  <div class="form-floating mb-3">
    <input type="text" (change)="handleChange('city', _person.city)" class="form-control" id="city" name="city"
      [(ngModel)]="_person.city" placeholder="City">
    <label for="city">City</label>
  </div>
  <div class="row">
    <div class="col-8 form-floating mb-3">
      <input type="text" (change)="handleChange('state', _person.state)" class="form-control" id="state" name="state"
        [(ngModel)]="_person.state" placeholder="State">
      <label for="state">State</label>
    </div>
    <div class="col-4 form-floating mb-3">
      <input type="text" (change)="handleChange('postal_code', _person.postal_code)" class="form-control"
        id="postal_code" name="postal_code" [(ngModel)]="_person.postal_code" placeholder="Postal Code">
      <label for="postal_code">Postal Code</label>
    </div>
  </div>
<div class="form-floating mb-3">
  <input type="text" (change)="handleChange('country', _person.country)" class="form-control" id="country" name="country"
    [(ngModel)]="_person.country" placeholder="Country">
  <label for="country">Country</label>
</div>
<div class="form-floating mb-3">
  <input type="text" (change)="handleChange('phone', _person.phone)" class="form-control" id="phone" name="phone"
    [(ngModel)]="_person.phone" placeholder="Phone">
  <label for="phone">Phone</label>
</div>
<div class="form-floating mb-3">
  <label for="dateOfBirth">Date of birth</label>
				<div class="input-group">
					<input
						id="dateOfBirth"
						class="form-control"
						placeholder="yyyy-mm-dd"
						name="birth_date"
            [(ngModel)]="_person.birth_date"
						ngbDatepicker
          />
					<button nbButton shape="semi-round" status="primary" class="btn btn-outline-secondary bi bi-calendar3" type="button"></button>
				</div>
</div>
<div class="form-floating mb-3">
  <input type="text" (change)="handleChange('fax', _person.fax)" class="form-control" id="fax" name="fax"
    [(ngModel)]="_person.fax" placeholder="Fax">
  <label for="fax">Fax</label>
</div>
<div class="form-floating mb-3">
  <input type="email" (change)="handleChange('email', _person.email)" class="form-control" id="email" name="email" [(ngModel)]="_person.email" placeholder="Email">
  <label for="email">Email</label>
</div>
<div class="form-floating mb-3">
  <input type="text" (change)="handleChange('ec_number', _person.ec_number)" class="form-control" id="ec_number" name="ec_number" [(ngModel)]="_person.ec_number"
    placeholder="EC Number">
  <label for="ec_number">EC Number</label>
</div>
<div class="form-floating mb-3">
  <input type="text" (change)="handleChange('usef_number', _person.usef_number)" class="form-control" id="usef_number" name="usef_number" [(ngModel)]="_person.usef_number"
    placeholder="USEF Number">
  <label for="usef_number">USEF Number</label>
</div>
<div class="form-floating mb-3">
  <input type="text" (change)="handleChange('ohja_number', _person.ohja_number)" class="form-control" id="ohja_number" name="ohja_number" [(ngModel)]="_person.ohja_number"
    placeholder="OHJA Number">
  <label for="ohja_number">OHJA Number</label>
</div>
<div class="form-floating mb-3">
  <input type="text" (change)="handleChange('ushja_number', _person.ushja_number)" class="form-control" id="ushja_number" name="ushja_number" [(ngModel)]="_person.ushja_number"
    placeholder="USHJA Number">
  <label for="ushja_number">USHJA Number</label>
</div>
<div class="form-floating mb-3">
  <input type="text" (change)="handleChange('stable', _person.stable)" class="form-control" id="stable" name="stable" [(ngModel)]="_person.stable" placeholder="Stable">
  <label for="stable">Stable</label>
</div>
<div class="form-floating mb-3">
  <input type="text" (change)="handleChange('fei_id', _person.fei_id)" class="form-control" id="fei_id" name="fei_id" [(ngModel)]="_person.fei_id" placeholder="FEI ID">
  <label for="fei_id">FEI ID</label>
</div>
<button nbButton shape="semi-round" status="primary" type="submit" class="btn btn-primary">Save</button>
</form>
