import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {User} from '../models/user';

import {AppSettings} from '../app.settings';

@Injectable({
  providedIn: 'root',
})
export class SmsApiSessionService {

  constructor(private http: HttpClient) {
  }

  getSession(): Promise<User> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'sms-session').subscribe((response: any) => {
          if (response.data && response.data.type === 'sms_session') {
            resolve(response.data.attributes.user);
          } else {
            reject(response);
          }

        },
        error_response => {
          if (error_response instanceof HttpErrorResponse) {
            reject(error_response.error);
          }
          reject(error_response);
        });
    });
  }
}
