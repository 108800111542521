<nb-card [accent]="!edit ? 'info' : 'warning'">
  <nb-card-header>
    <h2 class="nb-card-title">{{ edit ? 'EDIT' : 'ADD' }} PRIZE MONEY GROUP</h2>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="prizeForm" (ngSubmit)="onSubmit()" class="arena-validation" novalidate>
      <div class="row">
        <div class="col-4 form-floating mb-3">
          <label>PRIZE GROUP #</label>
          <input nbInput fullWidth type="number" formControlName="prize_group_number"
                 placeholder="PRIZE GROUP #" required>
        </div>
        <div class="col-8 mb-3">
          <label>NAME</label>
          <input nbInput fullWidth type="text" formControlName="name" placeholder="NAME">
        </div>
      </div>
    </form>

    <span>PLACINGS</span>
    <div class="row mb-3">
      <div class="col-2 mb-3" *ngFor="let place of places ; let i = index">
        <input nbInput fullWidth type="number" [placeholder]="i+1" [(ngModel)]="place.price">
      </div>
    </div>
    <div style="float: right">
      <button nbButton shape="semi-round" status="primary" type="submit" style="float: right"
              class="btn btn-primary" (click)="addPlaces()">Add more places
      </button>
      <br>
      <br>
      <br>
      <br>

      <span>Total prize money: {{ getTotalPrize() }} $</span>
      <br>
      <br>

      <button nbButton shape="semi-round" status="primary" type="submit" (click)="onSubmit()"
              class="btn-save">{{ edit ? 'Update' : 'Save' }}
      </button>
    </div>

  </nb-card-body>
</nb-card>
