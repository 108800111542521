<nb-card>
  <nb-card-header>
    <h2 class="card-title">Prize Groups</h2>
    <div>
      <button nbButton shape="semi-round" status="primary" class="btn add-button" (click)="addGroup('')">+Add Prize Group</button>
    </div>
  </nb-card-header>

  <nb-card-body>
    <table [nbTreeGrid]="dataSource" [nbSort]="dataSource">
      <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns"></tr>
      <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns"></tr>
      <ng-container *ngFor="let column of columnsHead; let index = index"
                    [nbTreeGridColumnDef]="column"
                    [showOn]="getShowOn(index)">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
          {{column}}
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">{{row.data[column.toLowerCase().split(' ').join('_')] || '-'}}</td>
      </ng-container>
      <ng-container [nbTreeGridColumnDef]="'actions'">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          <button nbButton shape="semi-round" status="primary" class="btn add-button" (click)="addGroup(row.data.id)">Edit</button>
        </td>
      </ng-container>
    </table>

<!--    <nb-list role="list">-->
<!--      <nb-list-item *ngFor="let prize of _prizes" style="display: inline-grid">-->
<!--        <div class="row">-->
<!--          <div class="col-5 mb-3">-->
<!--            <label for="code">NUMBER</label>-->
<!--            <p class="data" id="code">{{ prize.prize_group_number }}</p>-->
<!--          </div>-->
<!--          <div class="col-5 mb-3">-->
<!--            <label for="name">Name</label>-->
<!--            <p class="data" id="name">{{ prize.name }}</p>-->
<!--          </div>-->
<!--          <div class="col-2 mb-3">-->
<!--            <button nbButton shape="semi-round" status="primary" style="float: right" (click)="addGroup(prize?.id)">Edit</button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </nb-list-item>-->
<!--    </nb-list>-->
  </nb-card-body>
</nb-card>
