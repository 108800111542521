import {Component} from '@angular/core';
import {NbComponentStatus, NbToastrService} from '@nebular/theme';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HorseShowService} from '../../../../providers/horse-show.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'ngx-prize-money-add-edit',
  templateUrl: './prize-money-add-edit.component.html',
  styleUrls: ['./prize-money-add-edit.component.scss'],
})
export class PrizeMoneyAddEditComponent {
  edit: boolean = false;

  prizeForm: FormGroup = new FormGroup({
    prize_group_number: new FormControl('', [Validators.required, Validators.minLength(1)]),
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
  });
  private editPrizeGroup: any;
  private horse_show: any;

  places = [
    {price: ''},
    {price: ''},
    {price: ''},
    {price: ''},
    {price: ''},
    {price: ''},
    {price: ''},
    {price: ''},
    {price: ''},
    {price: ''},
    {price: ''},
    {price: ''},
  ];

  constructor(private showService: HorseShowService, private toastService: NbToastrService,
              private route: ActivatedRoute) {
    this.horse_show = JSON.parse(localStorage.getItem('sms_default_horse_show'));
    this.route.params.subscribe(params => {
      if (params['uuid']) {
        this.getPrize(params['uuid']);
      }
    });
  }

  onSubmit() {
    // {
    //   "id": "string",
    //   "horse_show_id": "string",
    //   "prize_group_number": 0,
    //   "name": "string",
    //   "prize_group_placings": [
    //     {
    //       "id": "string",
    //       "horse_show_id": "string",
    //       "prize_group_number": 0,
    //       "placing": 0,
    //       "prize_amount": 0
    //     }
    //   ]
    // }
    if (this.prizeForm.valid) {
      const body: any = {
        prize_group_number: this.prizeForm.controls['prize_group_number'].value,
        name: this.prizeForm.controls['name'].value,
        horse_show_id: this.horse_show.id,
        prize_group_placings: [],
      };
      this.places.forEach((el: any, index) => {
        if (el.price > 0) {
          body.prize_group_placings.push({
            prize_group_number: this.prizeForm.controls['prize_group_number'].value,
            horse_show_id: this.horse_show.id,
            placing: index + 1,
            prize_amount: el.price,
          });
        }
      });
      // console.log('BODY:: ', body);

      if (!this.edit) {
        this.showService.postPrizeGroup(body).then(res => {
          this.prizeForm.reset();
          this.toastService.success('', 'Prize group created');
          this.places.forEach((el: any, index) => {
            el.price = '';
          });
        }, error => {
          this.toastService.danger('', 'Failed to create prize group');
        });
      } else {
        body.id = this.editPrizeGroup.id;
        // @ts-ignore
        this.showService.putPrizeGroup(this.editPrizeGroup.id, body).then(res => {
          this.toastService.success('', 'Prize group updated');
        }, error => {
          this.toastService.danger('', 'Error updating prize group');
        });
      }
    }
  }

  getTotalPrize() {
    let total = 0;
    this.places.forEach((el: any) => {
      if (el.price > 0) {
        total = total + el.price;
      }
    });
    return total;
  }

  addPlaces() {
    this.places = this.places.concat(
      {price: ''},
      {price: ''},
      {price: ''},
      {price: ''},
      {price: ''},
      {price: ''},
    );
  }

  private getPrize(param: any) {
    this.edit = true;
    this.showService.getPrizeGroup(param).then((group: any) => {
      this.editPrizeGroup = group;
      this.prizeForm.controls['prize_group_number'].setValue(group.prize_group_number);
      this.prizeForm.controls['name'].setValue(group.name);
      group.prize_group_placings.forEach((el: any) => {
        this.places[el.placing - 1].price = el.prize_amount;
      });
    });
  }
}
