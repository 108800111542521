import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Horse_show_organizer } from '../../models/horse_show_organizer';

@Component({
  selector: 'ngx-app-b-horse-show-organizer',
  templateUrl: './b-horse-show-organizer.component.html',
  styleUrls: ['./b-horse-show-organizer.component.scss'],
})
export class BHorseShowOrganizerComponent implements OnInit {

  organizerForm: FormGroup = new FormGroup([]);

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.organizerForm = this.fb.group({
      number: [0, Validators.required],
      id: ['', Validators.required],
      name: ['', Validators.required],
      shortName: ['', Validators.required],
      paymentGateway: ['', Validators.required],
      merchantAccountId: ['', Validators.required],
      contactEmail: ['', [Validators.required, Validators.email]],
      convenienceFeeRate: [0, Validators.required],
      convenienceFeeWording: ['', Validators.required],
      convenienceFeeName: ['', Validators.required],
      collectConvenienceFee: [false],
      phone: ['', Validators.required],
      url: ['', Validators.required],
      merchant_descriptor_name: ['', Validators.required],
      primary_country_iso_3: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postal_code: ['', Validators.required],
      country: ['', Validators.required],
    });

  }

    onSubmit(): void {
      if (this.organizerForm.valid) {
        const newOrganizer = new Horse_show_organizer(this.organizerForm.value);
      }
    }


}
