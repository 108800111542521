export class Horse_show_organizer {

    number: number = 0;
    id: string = '';
    name: string = '';
    shortName: string = '';
    paymentGateway: string = '';
    merchantAccountId: string = '';
    contactEmail: string = '';
    convenienceFeeRate: number = 0; 
    convenienceFeeWording: string = '';
    convenienceFeeName: string = '';
    collectConvenienceFee: boolean = false;
    phone: string = '';
    url: string = '';
    merchant_descriptor_name: string = '';
    primary_country_iso_3: string = '';
    address: string = '';
    city: string = '';
    state: string = '';
    postal_code: string = '';
    country: string = '';

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }


}