export class User {
    id: string = '';
    username: string = '';
    password: string = '';
    email: string = '';
    user_person_id: string = '';
    enabled: boolean = false;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

}