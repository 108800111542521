import {Component, ElementRef, ViewChild} from '@angular/core';
import {HorseShowService} from '../../providers/horse-show.service';
import {Router} from '@angular/router';
import {NbDialogService, NbToastrService} from '@nebular/theme';

@Component({
  selector: 'ngx-app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  entry: any = {
    horses: [],
    owner: {},
    payer: {},
    payee: {},
    riders: [],
    trainer: {},
  };
  searching = {
    type: '',
    modalHeader: '',
    search: '',
  };
  entryNumber: any;
  getEntryAPI = false;
  getSearch = false;
  noResults = false;

  @ViewChild('dialogSearch', {static: true})
  dialogSearch: ElementRef;
  resultElements: any[] = [];
  competitions: any[] = [
    {
      name: '101.LOW WORKING HUNTER',
      rider: 'TRAVIS',
      flag: 'PRO',
      status: 'EL',
      date: 'SAT OCT 5, 2024',
      location: 'MAIN HUNTER RING',
      placing: '',
      prize: '$200',
    }, {
      name: '102.LOW WORKING HUNTER',
      rider: 'TRAVIS',
      flag: 'PRO',
      status: 'EL',
      date: 'SAT OCT 5, 2024',
      location: 'MAIN HUNTER RING',
      placing: '',
      prize: '$200',
    }, {
      name: '103.LOW WORKING HUNTER',
      rider: 'TRAVIS',
      flag: 'PRO',
      status: 'EL',
      date: 'SAT OCT 5, 2024',
      location: 'MAIN HUNTER RING',
      placing: '',
      prize: '$250',
    }, {
      name: '104.LOW WORKING HUNTER',
      rider: 'TRAVIS',
      flag: 'PRO',
      status: 'EL',
      date: 'SAT OCT 5, 2024',
      location: 'MAIN HUNTER RING',
      placing: '',
      prize: '$10',
    },
  ];
  horseShow: any;

  constructor(private showService: HorseShowService, private router: Router,
              private toastService: NbToastrService, private dialogService: NbDialogService) {

    showService.getEntities().then(res => {
    });
    this.horseShow = JSON.parse(localStorage.getItem('sms_default_horse_show'));
  }

  searchEntry() {
    if (!this.getEntryAPI) {
      this.getEntryAPI = true;
      setTimeout(() => {
        if (this.getEntryAPI) {
          this.showService.getHSEntriesByNumber(this.entryNumber).then(
            res => {
              this.getEntryAPI = false;
            }, error => {
              this.getEntryAPI = false;
            });
        }
      }, 1500);
    }
  }

  searchModal(type: string, header: string) {
    this.searching.modalHeader = header;
    this.searching.type = type;
    this.resultElements = [];
    this.searching.search = '';
    // @ts-ignore
    this.dialogService.open(this.dialogSearch, {closeOnBackdropClick: false});
  }

  search() {
    if (!this.getSearch) {
      this.getSearch = true;
      this.noResults = false;
      setTimeout(() => {
        if (this.searching.type === 'horse') {
          this.showService.getSearchHorse(this.searching.search).then(
            res => {
              this.noResults = res.length === 0;
              res.forEach(el => {
                el.name = el.current_name;
              });
              this.resultElements = res;
              this.getSearch = false;
            }, error => {
              this.getSearch = false;
            },
          );
        } else if (this.searching.type === 'entity') {
          this.showService.getSearchEntity(this.searching.search).then(
            res => {
              this.noResults = res.length === 0;
              this.resultElements = res;
              this.getSearch = false;
            }, error => {
              this.getSearch = false;
            },
          );
        } else if (this.searching.type === 'person') {
          this.showService.getSearchPeople(this.searching.search).then(
            res => {
              res.forEach(el => {
                el.name = el.first_name + ' ' + el.last_name;
              });
              this.noResults = res.length === 0;
              this.resultElements = res;
              this.getSearch = false;
            }, error => {
              this.getSearch = false;
            },
          );
        }
      }, 1500);
    }
  }

  addElement(element: any) {
    if (this.searching.type === 'person') {
      // get the person by id
      this.showService.getPersonById(element.id).then(
        res => {
          res.email_addresses.forEach((email: any) => {
            if (email.contact_preference === 1) {
              res.email = email.email_address;
            }
          });
          res.telephone_numbers.forEach((phone: any) => {
            if (phone.contact_preference === 1) {
              res.phone = '+' + phone.country_code + phone.telephone_number;
            }
          });
          if (this.searching.modalHeader.toLowerCase() === 'riders') {
            this.entry[this.searching.modalHeader.toLowerCase()].push(res);
          } else {
            this.entry[this.searching.modalHeader.toLowerCase()] = res;
          }
        },
      );
    } else if (this.searching.type === 'horse') {
      this.showService.getHorseMemberships(element.id).then(
        res => {
          element.memberships = res;
          this.entry[this.searching.modalHeader.toLowerCase()].push(element);
        },
      );
    }
  }

  protected readonly Object = Object;

  edit(type: string, i: number) {
    if (type === 'horse') {
      this.router.navigate(['horse-add-edit', {uuid: this.entry.horses[i].id}]).then();
    } else if (type === 'owner' || type === 'payer' || type === 'payee' || type === 'rider' || type === 'trainer') {
      this.router.navigate(['person-add-edit', {uuid: this.entry[type].id}]).then();
    } else if (type === 'rider') {
      this.router.navigate(['person-add-edit', {uuid: this.entry[type][i].id}]).then();
    }
  }

  previousEntry() {

  }

  nextEntry() {

  }

  resetForm() {
    this.entry = {
      horse: {},
      owner: {},
      payer: {},
      payee: {},
      rider: {},
      trainer: {},
    };
  }

  saveEntry() {
    if (this.entryNumber === undefined) {
      this.toastService.warning('', 'Please check the entry number');
      return;
    }

    const body = {
      horse_show_id: this.horseShow.id,
      entry_type_number: this.entryNumber,
      horses: [],
      payer_id: this.entry.payer.id,
      payee_id: this.entry.payee.id,
      rider_ids: [],
      trainer_id: this.entry.trainer.id,
    };

    this.entry.horses.forEach(horse => {
      body.horses.push({
        horse_id: horse.id,
        owner_ids: [
          this.entry.owner.id,
        ],
      });
    });

    this.entry.riders.forEach(rider => {
      body.rider_ids.push(rider.id);
    });
    this.showService.postEntry(body).then(
      res => {
        console.log(res);
      },
    );
  }
}
