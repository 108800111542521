import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Horse_show} from '../models/horse_show';
import {AppSettings} from '../app.settings';

@Injectable({
  providedIn: 'root',
})
export class HorseShowService {

  constructor(private http: HttpClient) {
  }

  horse_show_list: Map<String, Horse_show> = new Map<String, Horse_show>();

  get(id: string): Promise<Horse_show> {
    if (this.horse_show_list.has(id)) {
      return new Promise((resolve) => {
        const horse_show: Horse_show | undefined = this.horse_show_list.get(id);
        if (horse_show !== undefined) {
          resolve(horse_show);
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        return this.http.get(AppSettings.API_ENDPOINT + 'horse-shows/id/' + id).subscribe({
          next: (response: any) => {
            if (response.data && response.data.type === 'horse_show') {
              this.horse_show_list.set(id, response.data.attributes);
              resolve(response.data.attributes);
            }
          },
          error: (e: HttpErrorResponse) => reject(e),
          complete: () => console.info('call to retrieve horse show complete'),
        });
      });
    }
  }


  getDefault(): Promise<Horse_show> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'horse-shows/default').subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'horse_show') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getShowVenues(): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'venues').subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'venue') {
            resolve(response.data.included);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getVenue(uuid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'venue/id/' + uuid).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'venue') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  postVenue(body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(AppSettings.API_ENDPOINT + 'venue', body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'venue') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  // == ARENA ==
  getArena(arenaUuid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'arena/id/' + arenaUuid).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'arena') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getArenas(venueUuid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'arenas/venue/' + venueUuid).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'arena') {
            resolve(response.data.included);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  postArena(body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(AppSettings.API_ENDPOINT + 'arena', body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'arena') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  putArena(uuid: string, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.put(AppSettings.API_ENDPOINT + 'arena/id/' + uuid, body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'arena') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getBillingTemplate(template_id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + `accounting/bill-item-template/id/${template_id}`).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'bill_item_template') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getBillingTemplates(horse_show_id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + `accounting/bill-item-templates/horse-show/${horse_show_id}`)
        .subscribe({
          next: (response: any) => {
            if (response.data && response.data.type === 'bill_item_template') {
              resolve(response.data.included);
            }
          },
          error: (e: HttpErrorResponse) => reject(e),
          complete: () => console.info('call to retrieve horse show complete'),
        });
    });
  }

  postBillingTemplates(body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(AppSettings.API_ENDPOINT + 'accounting/bill-item-template/', body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'bill_item_template') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  putBillingTemplates(id: string, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.put(AppSettings.API_ENDPOINT + 'accounting/bill-item-template/id/' + id, body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'bill_item_template') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getBillingCategories(): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + `accounting/bill-item-categories`).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'bill_item_template') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getShowOrganizers(): Promise<Horse_show> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'horse-show-organizers').subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'horse_show_organizer') {
            resolve(response.data['included']);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse_show_organizer complete'),
      });
    });
  }

  getHorseShow(number: string): Promise<Horse_show> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'horse-shows/number/' + number).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'horse_show') {
            resolve(response.data['included']);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getHorseShows(): Promise<Horse_show> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'public/horse-shows').subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'horse_show') {
            resolve(response.data['included']);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  postHorseShow(body: object): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(AppSettings.API_ENDPOINT + 'horse-show', body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'horse_show') {

            resolve(response.data.attributes);

          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  putHorseShow(id: string, body: object): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.put(AppSettings.API_ENDPOINT + 'horse-show/' + id, body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'horse_show') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  postBillItemCategory(body: object): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(AppSettings.API_ENDPOINT + 'accounting/bill-item-category', body).subscribe({
        next: (response: any) => {
          resolve(response);
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getTaxTemplate(code: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'accounting/tax/' + code).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'tax_template') {
            resolve(response.data['included']);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getTaxTemplates(): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'accounting/taxes').subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'tax_template') {
            resolve(response.data['included']);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getBillingNominatingFees(): Promise<any> {
    const horse_show_id = JSON.parse(localStorage.getItem('sms_default_horse_show')).id;
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + `accounting/bill-item-template/horse-show/${horse_show_id}/nominating-fees`).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'bill_item_template') {
            resolve(response.data['included']);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }


  postTaxTemplates(body: object): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(AppSettings.API_ENDPOINT + 'accounting/tax', body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'tax_template') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  postTaxTemplatesItems(id: string, body: []): Promise<any> {
    // @ts-ignore
    const horse_show_id = JSON.parse(localStorage.getItem('sms_default_horse_show')).id;
    return new Promise((resolve, reject) => {
      return this.http.post(AppSettings.API_ENDPOINT + `accounting/tax/${id}/horse-show/${horse_show_id}/billing-templates`, body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'tax_template') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  putTaxTemplates(id: string, body: object): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.put(AppSettings.API_ENDPOINT + 'horse-show/' + id, body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'tax_template') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getPrizeGroup(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + `accounting/prize-group/${id}`).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'prize_group') {
            resolve(response.data['attributes']);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getPrizeGroups(): Promise<any> {
    const horse_show_id = JSON.parse(localStorage.getItem('sms_default_horse_show')).id;
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + `accounting/prize-groups/horse-show/${horse_show_id}`).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'prize_group') {
            resolve(response.data['included']);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  postPrizeGroup(body: []): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(AppSettings.API_ENDPOINT + `accounting/prize-group`, body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'prize_group') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
      });
    });
  }

  putPrizeGroup(id: string, body: object): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.put(AppSettings.API_ENDPOINT + 'accounting/prize-group' + id, body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'tax_template') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

// === SECTIONS ===
  getShowSections(): Promise<any> {
    const horse_show_id = JSON.parse(localStorage.getItem('sms_default_horse_show')).id;
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'sections/horse-show/' + horse_show_id).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'section') {
            resolve(response.data.included);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getSection(uuid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'section/id/' + uuid).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'section') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getSectionByNumber(number: number): Promise<any> {
    const horse_show_id = JSON.parse(localStorage.getItem('sms_default_horse_show')).id;
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + `/section/horse-show/${horse_show_id}/number/${number}`)
        .subscribe({
          next: (response: any) => {
            if (response.data && response.data.type === 'section') {
              resolve(response.data.attributes);
            }
          },
          error: (e: HttpErrorResponse) => reject(e),
          complete: () => console.info('call to retrieve horse show complete'),
        });
    });
  }

  postSection(body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(AppSettings.API_ENDPOINT + 'section', body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'section') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  putSection(id: string, body: object): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.put(AppSettings.API_ENDPOINT + 'section/id/' + id, body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'tax_template') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  // === COMPETITION === /competitions/horse-show/{id}
  getShowCompetitions(): Promise<any> {
    const horse_show_id = JSON.parse(localStorage.getItem('sms_default_horse_show')).id;
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'competitions/horse-show/' + horse_show_id).subscribe({
        next: (response: any) => {
          // console.log('competition:: ', response);
          if (response.data && response.data.type === 'competition') {
            resolve(response.data.included);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getSectionCompetitions(id: string): Promise<any> {
    const horse_show_id = JSON.parse(localStorage.getItem('sms_default_horse_show')).id;
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + `competitions/horse-show/${horse_show_id}/section/number/${id}`)
        .subscribe({
          next: (response: any) => {
            // console.log('competition:: ', response);
            if (response.data && response.data.type === 'competition') {
              resolve(response.data.included);
            }
          },
          error: (e: HttpErrorResponse) => reject(e),
          complete: () => console.info('call to retrieve horse show complete'),
        });
    });
  }

  getCompetitionsRules(code: string): Promise<any> {
    const federationCode = JSON.parse(localStorage.getItem('sms_default_horse_show')).federation_code;
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + `competition-rules/federation/${federationCode}/discipline/${code}`)
        .subscribe({
          next: (response: any) => {
            if (response.data && response.data.type === 'competition_rule') {
              resolve(response.data.included);
            }
          },
          error: (e: HttpErrorResponse) => reject(e),
          complete: () => console.info('call to retrieve horse show complete'),
        });
    });
  }

  getCompetition(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'competition/id/' + id).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'competition') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  postCompetition(body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(AppSettings.API_ENDPOINT + 'competition', body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'competition') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  putCompetition(id: string, body: object): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.put(AppSettings.API_ENDPOINT + 'competition/id/' + id, body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'competition') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  // ENTRIES

  getEntries(): Promise<any> {
    const horse_show_id = JSON.parse(localStorage.getItem('sms_default_horse_show')).id;
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'entry-types/horse-show/' + horse_show_id).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'entry_type') {
            resolve(response.data.included);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getEntry(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'entry-type/' + id).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'entry_type') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  postEntryType(body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(AppSettings.API_ENDPOINT + 'entry-type', body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'entry_type') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  putEntryType(id: string, body: object): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.put(AppSettings.API_ENDPOINT + 'entry-type/id/' + id, body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'entry_type') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  // PERSON

  getPeople(): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'people').subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'person') {
            resolve(response.data.included);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getSinglePeople(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'person/' + id).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'person') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  getSearchPeople(search: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(AppSettings.API_ENDPOINT + 'people/search?search_term=' + search).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'person') {
            resolve(response.data.included);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  postPerson(body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(AppSettings.API_ENDPOINT + 'person', body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'person') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  putPerson(id: string, body: object): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.put(AppSettings.API_ENDPOINT + 'person/' + id, body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'person') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  postPersonNumber(body: any, person_id: string, priority: number): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(AppSettings.API_ENDPOINT + `telephone-number/person/${person_id}/contact-priority/${priority}`
        , body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'telephone_number') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  postPersonAddress(body: any, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(AppSettings.API_ENDPOINT + 'postal-address/person/' + id, body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'postal_address') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }

  // ADD PERSONAL EMAIL ADDRESS
  postPersonEmail(body: any, id: string, priority: number): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(AppSettings.API_ENDPOINT + `email-address/person/${id}/contact-priority/${priority}`, body)
        .subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'email_address') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }



  // MEMBERSHIPS

  postPersonMemberships(body: any, person_id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(AppSettings.API_ENDPOINT + `membership/person/${person_id}`, body).subscribe({
        next: (response: any) => {
          if (response.data && response.data.type === 'membership') {
            resolve(response.data.attributes);
          }
        },
        error: (e: HttpErrorResponse) => reject(e),
        complete: () => console.info('call to retrieve horse show complete'),
      });
    });
  }
}
