import {Component, OnInit} from '@angular/core';
import {SessionService} from '../../providers/session.service';
import {Horse_show} from '../../models/horse_show';

@Component({
  selector: 'ngx-app-current-horse-show',
  templateUrl: './current-horse-show.component.html',
  styleUrls: ['./current-horse-show.component.scss'],
})
export class CurrentHorseShowComponent implements OnInit {

  horse_show: Horse_show = new Horse_show({name: 'No Horse Show Selected'});
  constructor(private sessionService: SessionService) {
  }

  ngOnInit(): void {
    this.sessionService.horse_show$.subscribe(horse_show => {
      this.horse_show = horse_show;
    });
  }

  handleChangeHorseShow(new_show_number: any) {
    alert('Change show to ...' + new_show_number);
  }

}
