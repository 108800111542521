<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" routerLink="" (click)="navigateHome()">SHOW MANAGEMENT SYSTEM</a>
  </div>
<!--  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">-->
<!--    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>-->
<!--  </nb-select>-->
</div>

<div class="header-container">
  <p style="margin: auto; font-weight: bold">{{horseShow?.name}}</p>

  <nb-actions size="small">
    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu" nbContextMenuTag="context-menu"
               [name]="user?.username">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
