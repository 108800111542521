<nb-card>
  <nb-card-header style="    display: flex;
    justify-content: space-between;">
    <h2 class="card-title">COMPETITIONS </h2>
    <nb-select *ngIf="_sections.length > 0" style="width: 400px;" placeholder="Select Section" [(ngModel)]="sectionSelected" (selectedChange)="selectSection()">
      <nb-option *ngFor="let section of _sections" [value]="section">{{ section.name }}</nb-option>
    </nb-select>
    <button nbButton [disabled]="sectionSelected === ''" nbPopover="{{sectionSelected === '' ? 'PLEASE SELECT SECTION':''}}" nbPopoverTrigger="hover"
            nbPopoverPlacement="bottom"
            shape="semi-round" status="primary" class="btn add-button" (click)="addEditCompetition('')">+Add Competition</button>
  </nb-card-header>
  <nb-card-body>
    <table [nbTreeGrid]="dataSource" [nbSort]="dataSource" *ngIf="competitions.length>0">
      <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns"></tr>
      <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns"></tr>
      <ng-container *ngFor="let column of columnsHead; let index = index"
                    [nbTreeGridColumnDef]="column"
                    [showOn]="getShowOn(index)">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
          {{column}}
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">{{row.data[column.toLowerCase().split(' ').join('_')] || '-'}}</td>
      </ng-container>
      <ng-container [nbTreeGridColumnDef]="'actions'">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          <button nbButton shape="semi-round" status="primary" class="btn add-button" (click)="addEditCompetition(row.data.id)">Edit</button>
        </td>
      </ng-container>
    </table>
  </nb-card-body>
</nb-card>
