<nb-card>
  <nb-card-header>
    <h2 class="card-title">ADD / EDIT ENTRY</h2>
    <p>ENTRY (BRIDLE) #</p>
    <input nbInput type="text" (keyup)="searchEntry()" [(ngModel)]="entryNumber" style="width: 30svw"
           placeholder="NUMBER TO IDENTIFY THIS ENTRY">
  </nb-card-header>
  <nb-tabset>
    <nb-tab tabTitle="ENTRY COMPONENTS">

      <ng-template #dialogSearch let-data let-ref="dialogRef">
        <nb-card size="large" style="width: 40svw;">
          <nb-card-header>SEARCH {{ searching.modalHeader }}
            <input nbInput fullWidth type="text" [(ngModel)]="searching.search" name="searchInput"
                   placeholder="Search..." (keyup)="search()" required>
          </nb-card-header>
          <nb-card-body>
            <nb-list>
              <nb-list-item *ngIf="noResults">
                <p>There are no results found!</p>
              </nb-list-item>
              <nb-list-item *ngFor="let element of resultElements" (click)="addElement(element); ref.close()"
                            style="cursor: pointer">
                {{ element.name }}
              </nb-list-item>
            </nb-list>
          </nb-card-body>
          <nb-card-footer style="justify-content: space-around;display: flex;">
            <button nbButton status="danger" (click)="ref.close()">CLOSE</button>
          </nb-card-footer>
        </nb-card>
      </ng-template>

      <nb-card>
        <h4>HORSE / OWNER</h4>
        <p>The number of horses setting on the Active Entry Type determines the number of horses which can be set
          here.
          Each horse on the entry can have one or more owners</p>
        <div class="row">
          <div class="col-10" style="display: inline-flex;" *ngIf="entry.horses.length === 0">
            <div class="row" name="HORSE" style="width: inherit;">
              <div class="col-1">
                <span class="add-button" (click)="searchModal('horse', 'HORSES')"><nb-icon
                  icon="plus-outline"></nb-icon></span>
              </div>
              <div class="col-5">
                <p style="margin: 40px">PLEASE ADD HORSE</p>
              </div>

            </div>
          </div>
          <div class="col-10" *ngIf="entry.horses.length !== 0">
            <div class="row" name="HORSE" *ngFor="let horse of entry.horses, let i = index">
              <div class="col-1">
                  <span *ngIf="i === 0" class="add-button" (click)="searchModal('horse', 'HORSES')"><nb-icon
                    icon="plus-outline"></nb-icon></span>
                <nb-icon icon="edit-2-outline" (click)="edit('horse', i)" class="edit-button"></nb-icon>
              </div>
              <div class="col-5">
                <label>NAME</label>
                <p style="font-weight: bold">{{ horse.name }}</p>
                <div class="block-details">
                  <div>
                    <label>COLOR
                      <span>{{ horse.color }}</span>
                    </label>
                  </div>
                  <div>
                    <label>GENDER <span>{{ horse.gender }}</span></label>
                  </div>
                  <div>
                    <label>BIRTH-DATE <span>{{ horse.date_of_birth | date }}</span></label>
                  </div>
                </div>
              </div>
              <div class="col-2" style="display: grid; text-align: end">
                <label>FEDERATION</label>
                <p *ngFor="let member of horse.memberships">{{ member.federation }}
                  <nb-icon *ngIf="member.valid" icon="checkmark-square-2-outline" class="check-green"></nb-icon>
                  <nb-icon *ngIf="!member.valid" icon="close-square-outline" class="check-red"></nb-icon>
                </p>
              </div>
              <div class="col-1" style="display: grid; text-align: start">
                <label>ID #</label>
                <p *ngFor="let member of horse.memberships">{{ member.membership_id }}</p>
              </div>
              <div class="col-1" style="display: grid; text-align: end">
                <span class="minus-button" (click)="entry.horses.splice(i,1)"><nb-icon
                  icon="minus-outline"></nb-icon></span>
              </div>
              <div class="col-2">
                <div class="block-info ">
                  <p>USEF: [active]</p>
                  <p>date1 - date2</p>
                  <p>MICROCHIP PRESENT OWNER: SWIFT, T</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-10">
            <div class="row" name="OWNER">
              <div class="col-1">
                <span class="add-button" (click)="searchModal('person', 'OWNER')"><nb-icon
                  [icon]="Object.keys(entry.owner).length === 0 ? 'plus-outline' : 'flip-2-outline'"></nb-icon></span>
                <nb-icon *ngIf="Object.keys(entry.owner).length !== 0" icon="edit-2-outline"
                         (click)="edit('owner',0)" class="edit-button"></nb-icon>
              </div>
              <div class="col-5" *ngIf="Object.keys(entry.owner).length === 0">
                <p style="margin: 40px">PLEASE ADD OWNER</p>
              </div>
              <div class="col-5" *ngIf="Object.keys(entry.owner).length !== 0">
                <label>NAME</label>
                <p style="font-weight: bold">{{ entry.owner.name }}</p>
                <div class="block-details">
                  <div>
                    <label>ADDRESS
                      <span>{{ entry.owner?.postal_address?.locality }} {{ entry.owner?.postal_address?.address_line1 }}</span></label>
                  </div>
                  <div>
                    <label>BIRTH DATE <span>{{ entry.owner?.birth_date | date }}</span></label>
                  </div>
                  <div>
                    <label>EMAIL <span>{{ entry.owner?.email }}</span></label>
                  </div>
                  <div>
                    <label>PHONE <span>{{ entry.owner?.phone }}</span></label>
                  </div>
                </div>
              </div>
              <div class="col-2" style="display: grid; text-align: end" *ngIf="Object.keys(entry.owner).length !== 0">
                <label>FEDERATION</label>
                <p *ngFor="let member of entry.owner.memberships">{{ member.federation }}
                  <nb-icon *ngIf="member.valid" icon="checkmark-square-2-outline" class="check-green"></nb-icon>
                  <nb-icon *ngIf="!member.valid" icon="close-square-outline" class="check-red"></nb-icon>
                </p>
              </div>
              <div class="col-1" style="display: grid; text-align: start"
                   *ngIf="Object.keys(entry.owner).length !== 0">
                <label>ID #</label>
                <p *ngFor="let member of entry.owner.memberships">{{ member.membership_id }}</p>
              </div>
              <div class="col-1" style="display: grid; text-align: end" *ngIf="Object.keys(entry.owner).length !== 0">
                <span class="minus-button" (click)="entry['owner'] = {}"><nb-icon
                  icon="minus-outline"></nb-icon></span>
              </div>
              <div class="col-2" *ngIf="Object.keys(entry.owner).length !== 0">
                <div class="block-info ">
                  <p>USEF: [active]</p>
                  <p>date1 - date2</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2" *ngIf="Object.keys(entry.owner).length !== 0">
            <p>OWNER SIGNATURES</p>
            <p>
              <nb-icon icon="checkmark-square-2-outline" class="check-green"></nb-icon>
              ENTRY AGREEMENT ELECTRONIC
            </p>
            <p>
              <nb-icon icon="checkmark-square-2-outline" class="check-green"></nb-icon>
              WAIVER / RELEASE ELECTRONIC
            </p>
          </div>
        </div>
      </nb-card>

      <div class="row" style="justify-content: space-around">
        <nb-card class="col-5" style="padding: 1px">
          <nb-card-body class="payee-payer">
            <div style="margin-right: 20px">
              <span class="add-button" (click)="searchModal('person', 'PAYER')"><nb-icon [icon]="Object.keys(entry.payer).length === 0 ? 'plus-outline' : 'flip-2-outline'"></nb-icon></span>
              <nb-icon *ngIf="Object.keys(entry.payer).length !== 0"
                       (click)="edit('payer', 0)" icon="edit-2-outline" class="edit-button"></nb-icon>
            </div>
            <div>
              <label><span class="data">PAYER</span> - Entity that pays for this entry</label>
              <p style="font-weight: bold" *ngIf="Object.keys(entry.payer).length !== 0">{{ entry.payer?.name }}</p>
              <p style="margin: 40px" *ngIf="Object.keys(entry.payer).length === 0">PLEASE ADD PAYER</p>
            </div>
            <span class="minus-button" *ngIf="Object.keys(entry.payer).length !== 0" (click)="entry['payer'] = {}"><nb-icon
              icon="minus-outline"></nb-icon></span>
          </nb-card-body>
        </nb-card>

        <nb-card class="col-5" style="padding: 1px">
          <nb-card-body class="payee-payer">
            <div  style="margin-right: 20px">
              <span class="add-button" (click)="searchModal('person', 'PAYEE')"><nb-icon [icon]="Object.keys(entry.payee).length === 0 ? 'plus-outline' : 'flip-2-outline'"></nb-icon></span>
              <nb-icon *ngIf="Object.keys(entry.payee).length !== 0"
                       (click)="edit('payee', 0)" icon="edit-2-outline" class="edit-button"></nb-icon>
            </div>
            <div>
              <label><span class="data">PAYEE</span> - Entity that receives prize money for this entry</label>
              <p *ngIf="Object.keys(entry.payee).length !== 0" style="font-weight: bold">{{ entry.payee?.name }}</p>
              <p style="margin: 40px" *ngIf="Object.keys(entry.payee).length === 0">PLEASE ADD PAYEE</p>
            </div>
            <span class="minus-button" *ngIf="Object.keys(entry.payee).length !== 0" (click)="entry['payee'] = {}"><nb-icon
              icon="minus-outline"></nb-icon></span>
          </nb-card-body>
        </nb-card>
      </div>

      <nb-card>
        <div style="display: flex">
          <h4>RIDERS</h4>
          <span class="add-button" style="margin-left: 20px;" (click)="searchModal('person', 'RIDERS')"><nb-icon
            icon="plus-outline"></nb-icon></span>
        </div>

        <div class="row">
          <div class="col-10" *ngIf="entry.riders.length === 0">
            <p style="margin: 40px">PLEASE ADD RIDER</p>
          </div>
          <div class="col-10" *ngIf="entry.riders.length !== 0">
            <div class="row" name="RIDER" *ngFor="let rider of entry.riders, let i = index">
              <div class="col-1">
                <nb-icon icon="edit-2-outline" (click)="edit('rider', i)" class="edit-button"></nb-icon>
              </div>
              <div class="col-5">
                <label>NAME</label>
                <p style="font-weight: bold">{{ rider.name }}</p>
                <div class="block-details">
                  <div>
                    <label>ADDRESS
                      <span>{{ rider?.postal_address?.locality }} {{rider?.postal_address?.address_line1 }}</span></label>
                  </div>
                  <div>
                    <label>BIRTH DATE <span>{{ rider?.birth_date | date }}</span></label>
                  </div>
                  <div>
                    <label>EMAIL <span>{{ rider?.email }}</span></label>
                  </div>
                  <div>
                    <label>PHONE <span>{{ rider?.phone }}</span></label>
                  </div>
                </div>
              </div>
              <div class="col-2" style="display: grid; text-align: end">
                <label>FEDERATION</label>
                <p *ngFor="let member of rider.memberships">{{ member.federation }}
                  <nb-icon *ngIf="member.valid" icon="checkmark-square-2-outline" class="check-green"></nb-icon>
                  <nb-icon *ngIf="!member.valid" icon="close-square-outline" class="check-red"></nb-icon>
                </p>
              </div>
              <div class="col-1" style="display: grid; text-align: start">
                <label>ID #</label>
                <p *ngFor="let member of rider.memberships">{{ member.membership_id }}</p>
              </div>
              <div class="col-1" style="display: grid; text-align: end">
                <span class="minus-button" (click)="entry.riders.splice(i,1)"><nb-icon
                  icon="minus-outline"></nb-icon></span>
              </div>
              <div class="col-2">
                <div class="block-info ">
                  <p>USEF: [active]</p>
                  <p>date1 - date2</p>
                </div>
              </div>
            </div>

          </div>
          <div class="col-2" *ngIf="entry.riders.length !== 0">
            <label>RIDER SIGNATURES</label>
            <p>
              <nb-icon icon="checkmark-square-2-outline" class="check-green"></nb-icon>
              ENTRY AGREEMENT ELECTRONIC
            </p>
            <p>
              <nb-icon icon="checkmark-square-2-outline" class="check-green"></nb-icon>
              WAIVER / RELEASE ELECTRONIC
            </p>
          </div>
        </div>
      </nb-card>
      <nb-card>
        <div style="display: flex">
          <h4>TRAINER</h4>
        </div>

        <div class="row">
          <div class="col-10" *ngIf="Object.keys(entry.trainer).length === 0">
              <span class="add-button" (click)="searchModal('person', 'TRAINER')"><nb-icon
                [icon]="Object.keys(entry.trainer).length === 0 ? 'plus-outline' : 'flip-2-outline'"></nb-icon></span>
            <p style="margin: 40px">PLEASE ADD TRAINER</p>
          </div>
          <div class="col-10" *ngIf="Object.keys(entry.trainer).length !== 0">
            <div class="row" name="trainer">
              <div class="col-1">
                <span class="add-button" (click)="searchModal('person', 'TRAINER')"><nb-icon
                  icon="plus-outline"></nb-icon></span>
                <nb-icon icon="edit-2-outline" (click)="edit('trainer', 0)" class="edit-button"></nb-icon>
              </div>
              <div class="col-5">
                <label>NAME</label>
                <p style="font-weight: bold">{{ entry.trainer.name }}</p>
                <div class="block-details">
                  <div>
                    <label>ADDRESS
                      <span>{{ entry.trainer?.postal_address?.locality }} {{ entry.trainer?.postal_address?.address_line1 }}</span></label>
                  </div>
                  <div>
                    <label>BIRTH DATE <span>{{ entry.trainer?.birth_date | date }}</span></label>
                  </div>
                  <div>
                    <label>EMAIL <span>{{ entry.trainer?.email }}</span></label>
                  </div>
                  <div>
                    <label>PHONE <span>{{ entry.trainer?.phone }}</span></label>
                  </div>
                </div>
              </div>
              <div class="col-2" style="display: grid; text-align: end">
                <label>FEDERATION</label>
                <p *ngFor="let member of entry.trainer.memberships">{{ member.federation }}
                  <nb-icon *ngIf="member.valid" icon="checkmark-square-2-outline" class="check-green"></nb-icon>
                  <nb-icon *ngIf="!member.valid" icon="close-square-outline" class="check-red"></nb-icon>
                </p>
              </div>
              <div class="col-1" style="display: grid; text-align: start">
                <label>ID #</label>
                <p *ngFor="let member of entry.trainer.memberships">{{ member.membership_id }}</p>
              </div>
              <div class="col-1" style="display: grid; text-align: end">
                <span class="minus-button" (click)="entry['trainer'] = {}"><nb-icon
                  icon="minus-outline"></nb-icon></span>
              </div>
              <div class="col-2">
                <div class="block-info ">
                  <p>USEF: [active]</p>
                  <p>date1 - date2</p>
                </div>
              </div>
            </div>

          </div>
          <div class="col-2" *ngIf="Object.keys(entry.trainer).length !== 0">
            <label>RIDER SIGNATURES</label>
            <p>
              <nb-icon icon="checkmark-square-2-outline" class="check-green"></nb-icon>
              ENTRY AGREEMENT ELECTRONIC
            </p>
            <p>
              <nb-icon icon="checkmark-square-2-outline" class="check-green"></nb-icon>
              WAIVER / RELEASE ELECTRONIC
            </p>
          </div>
        </div>
      </nb-card>
      <nb-card-footer style="width: 100%; justify-content: space-between; display: flex">
        <div>
          <button nbButton shape="semi-round" (click)="previousEntry()" style="width: 70px; margin-right: 30px">
            <nb-icon icon="arrowhead-left-outline"></nb-icon>
          </button>
          <button nbButton shape="semi-round" (click)="nextEntry()" style="width: 70px;">
            <nb-icon icon="arrowhead-right-outline"></nb-icon>
          </button>
        </div>
        <button nbButton shape="semi-round" status="warning" (click)="resetForm()">
          <nb-icon icon="trash-2-outline"></nb-icon>
          RESET
        </button>
        <button nbButton shape="semi-round" status="primary" type="submit" (click)="saveEntry()">
          <nb-icon icon="save-outline"></nb-icon>
          SAVE
        </button>
      </nb-card-footer>
    </nb-tab>

    <nb-tab tabTitle="COMPETITIONS">
      <table>
        <tr>
          <th>COMP #</th>
          <th>RIDER</th>
          <th>FLAGS</th>
          <th>STATUS</th>
          <th>DATE</th>
          <th>LOCATION</th>
          <th>PLACING</th>
          <th>PRIZE</th>
        </tr>
        <tr *ngFor="let competition of competitions">
          <td>{{ competition.name }}</td>
          <td>{{ competition.rider }}</td>
          <td>{{ competition.flag }}</td>
          <td>{{ competition.status }}</td>
          <td>{{ competition.date }}</td>
          <td>{{ competition.location }}</td>
          <td>{{ competition.placing }}</td>
          <td>{{ competition.prize }}</td>
        </tr>
      </table>
      <div class="row">
        <button nbButton shape="semi-round" status="primary" class="btn add-button">ADD COMPETITION</button>
        <button nbButton shape="semi-round" status="primary" class="btn add-button">ADD SECTION</button>
        <button nbButton shape="semi-round" status="primary" class="btn add-button">CHANGE RIDER</button>
        <button nbButton shape="semi-round" status="primary" class="btn add-button">SCRATCH</button>
        <button nbButton shape="semi-round" status="primary" class="btn add-button">VOID</button>
        <button nbButton shape="semi-round" status="primary" class="btn add-button">APPLY LATE FEE</button>
      </div>
    </nb-tab>
    <nb-tab tabTitle="BILLING">
    </nb-tab>
    <nb-tab tabTitle="JOURNAL">
    </nb-tab>
    <nb-tab tabTitle="FOLIO">
    </nb-tab>
  </nb-tabset>
</nb-card>
