import {HttpHeaders} from '@angular/common/http';
import * as uuid from 'uuid';

export class AppSettings {

    // public static API_ENDPOINT='http://localhost:5003/';
    public static API_ENDPOINT = 'https://business-api.showmanagementsystem.com/';

    public static AUTH_TOKEN_NAME = 'sms_cloud_app_client_access_token';

    public static APP_VERSION_CHECK_PATH = './assets/version.json';

    public static DEFAULT_HORSE_SHOW_ORGANIZER_STORAGE = 'sms_default_horse_show_organizer';
    public static DEFAULT_HORSE_SHOW_STORAGE = 'sms_default_horse_show';
    public static SMS_APP_SOURCE_ID = uuid.v4().toString();

    public static DEFAULT_HTTP_OPTIONS = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }),
    };

    public static HTTP_OPTIONS_FOR_DOCUMENT_UPLOAD = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
        }),
    };

    public static APP_VERSION = '1.01';

}
