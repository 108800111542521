import { Component, EventEmitter, Output } from '@angular/core';
import { faSearch, faSpinner, faUser, faPlus } from '@fortawesome/sharp-light-svg-icons';
// @ts-ignore
// import { OperatorFunction, Observable, debounceTime, distinctUntilChanged, switchMap, catchError, of } from 'rxjs';
import { PersonService } from '../../providers/person.service';
import { Person } from '../../models/person';

@Component({
  selector: 'ngx-app-person-search',
  templateUrl: './person-search.component.html',
  styleUrls: ['./person-search.component.scss'],
})
export class PersonSearchComponent {

  constructor(private personService: PersonService) { }

  searching = false;
  searchFailed = false;
  faSearch = faSearch;
  faUser = faUser;
  faSpinner = faSpinner;


  @Output() personSelected$: EventEmitter<Person> = new EventEmitter<Person>();

  // search: OperatorFunction<string, readonly Person[]> = (text$: Observable<string>) => text$.pipe(
  //     debounceTime(300),
  //     distinctUntilChanged(),
  //     tap(() => (this.searching = true)),
  //     switchMap((term) =>
  //       this.personService.getPersonSearchResults(term).pipe(
  //         tap(() => (this.searchFailed = false)),
  //         catchError((erro) => {
  //           this.searchFailed = true;
  //           this.searching = false;
  //           return of([]);
  //         }),
  //       ),
  //     ),
  //     tap(() => (this.searching = false)),
  //   )
  //
  //   onSelectPerson(record: any, ctrl: any) {
  //     // Not sure when this is useful
  //     this.personSelected$.emit(record.item);
  //     this.searching = false;
  //   }
  //
  //   handleSelectPerson(person: Person) {
  //     this.personSelected$.emit(person);
  //     this.searching = false;
  //   }

}
