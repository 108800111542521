import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faUser } from '@fortawesome/sharp-light-svg-icons';
import { Person } from '../../models/person';

@Component({
  selector: 'ngx-app-modal-person',
  templateUrl: './modal-person.component.html',
  styleUrls: ['./modal-person.component.scss'],
})
export class ModalPersonComponent {

  @Input() person: Person = new Person();
  @Output() person_saved$: EventEmitter<Person> = new EventEmitter<Person>();

  faUser = faUser;

  constructor() {
  }

  handlePersonSaved(person: any) {
    this.person_saved$.emit(person);
  }
}
