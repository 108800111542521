import { Component } from '@angular/core';
import {HorseShowService} from '../../../providers/horse-show.service';
import {Router} from '@angular/router';
import {NbTreeGridDataSource, NbTreeGridDataSourceBuilder} from '@nebular/theme';

@Component({
  selector: 'ngx-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss'],
})
export class SectionsComponent {
  columnsHead = ['Section Number', 'Name', 'Entry Fee', 'Fee Option', 'Discipline Code'];
  allColumns = [...this.columnsHead, 'actions'];
  dataSource: NbTreeGridDataSource<any>;
  private data = [];
  constructor(private showService: HorseShowService, private router: Router,
              private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>) {
    this.getSections();
  }

  private getSections() {
    this.showService.getShowSections().then(sections => {
      sections.forEach((el: any) => {
        el.country = el.country_iso_alpha_3;
        Object.keys(el).forEach(key => {
          if (typeof el[key] === 'boolean') {
            el[key] = el[key].toString();
          }
        });
        this.data.push({data: el});
      });
      this.dataSource = this.dataSourceBuilder.create(this.data);
    });
  }

  addEditSections(uuid: string) {
    if (uuid !== '') {
      this.router.navigate(['section-add-edit', {uuid: uuid}]).then();
    } else {
      this.router.navigate(['section-add-edit']).then();
    }
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }
}
