<nb-card [accent]="!edit ? 'info' : 'warning'">
  <nb-card-header>
    <h2 class="nb-card-title">{{ edit ? 'EDIT' : 'ADD' }} SECTIONS & COMPETITIONS</h2>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="sectionForm" (ngSubmit)="onSubmit()" class="needs-validation" novalidate>
      <div class="row">

        <div class="col-4 mb-3">
          <label>SECTION #</label>
          <input nbInput fullWidth type="number" formControlName="section_number" id="validationCustom02"
                 name="name"
                 placeholder="COMPETITION #" required>
        </div>
        <div class="col-8 mb-3">
          <label>SECTION NAME</label>
          <input nbInput fullWidth type="text" formControlName="name" id="name" name="name"
                 placeholder="Name" required>
        </div>


        <div class="col-12 mb-3">
          <label>FEE OPTION</label>
          <nb-radio-group formControlName="fee_option" name="ng-model-group" (valueChange)="checkFee($event)">
            <nb-radio [value]="'C'">CHARGE ENTRY FEE PER COMPETITION</nb-radio>
            <nb-radio [value]="'F'">CHARGE SECTION ENTRY FEE</nb-radio>
            <nb-radio [value]="'D'">APPLY DISCOUNTED ENTRY FEE</nb-radio>
          </nb-radio-group>
        </div>
        <div class="col-4 mb-3">
          <label>ENTRY FEE</label>
          <input nbInput fullWidth type="number" formControlName="entry_fee" id="entry_fee" name="entry_fee" [disabled]="sectionForm.value['fee_option'] === 'C'"
                 placeholder="ENTRY FEE" required>
        </div>
        <div class="col-8 mb-3">
        </div>


        <div class="col-6 mb-3">
          <label>DISCIPLINE CODE</label>
          <nb-select fullWidth placeholder="Select discipline"
                     formControlName="discipline_code">
            <nb-option *ngFor="let discipline of _discipline" [value]="discipline.option">{{ discipline.name }}
            </nb-option>
          </nb-select>
        </div>
        <div class="col-6 mb-3" *ngIf="horse_show.federation_code">
          <label>USEF SECTION CODE</label>
          <input nbInput fullWidth type="text" formControlName="usef_section_code" id="usef_section_code"
                 name="usef_section_code"
                 placeholder="USEF SECTION CODE" required>
        </div>
      </div>
    </form>

    <button nbButton shape="semi-round" status="primary" type="submit" (click)="onSubmit()"
            class="btn-save">{{ edit ? 'UPDATE SECTION' : 'ADD SECTION' }}
    </button>
    <br>
    <br>
    <div *ngIf="edit" style="margin-top: 30px">
      <h2>COMPETITIONS IN THIS SECTION</h2>
      <p *ngIf="_competitions.length === 0"> There are no competitions for this section</p>
      <div *ngIf="_competitions.length > 0">
        <nb-card-header class="row">
          <p class="col-2">Number</p>
          <p class="col-4">Name</p>
          <p class="col-2">Entry Fee</p>
          <p class="col-4">Date</p>
        </nb-card-header>
        <nb-list>
          <nb-list-item *ngFor="let competition of _competitions">
            <p class="col-2">{{ competition.competition_number }}</p>
            <p class="col-4">{{ competition.name }}</p>
            <p class="col-2">{{ competition.entry_fee }}</p>
            <p class="col-4">{{ competition.date | date }}</p>
          </nb-list-item>
        </nb-list>
      </div>

      <button nbButton shape="semi-round" status="primary" type="submit" (click)="addCompetition()"
              class="btn-save">{{ 'ADD COMPETITION' }}
      </button>
    </div>
  </nb-card-body>
</nb-card>
