import { Component } from '@angular/core';
import {HorseShowService} from '../../../providers/horse-show.service';
import {Router} from '@angular/router';
import {NbTreeGridDataSource, NbTreeGridDataSourceBuilder} from '@nebular/theme';

@Component({
  selector: 'ngx-prize-money',
  templateUrl: './prize-money.component.html',
  styleUrls: ['./prize-money.component.scss'],
})
export class PrizeMoneyComponent {
  _prizes: any = [];
  columnsHead = ['Prize Group Number', 'Name'];
  allColumns = [...this.columnsHead, 'actions'];
  dataSource: NbTreeGridDataSource<any>;
  private data = [];
  constructor(private showService: HorseShowService, private router: Router,
              private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>) {
    this.getPrizeGroups();
  }

  getPrizeGroups() {
    this.showService.getPrizeGroups().then((prizes: []) => {
      this._prizes = prizes;
      prizes.forEach((el: any) => {
        Object.keys(el).forEach(key => {
          if (typeof el[key] === 'boolean') {
            el[key] = el[key].toString();
          }
        });
        this.data.push({data: el});
      });
      this.dataSource = this.dataSourceBuilder.create(this.data);
    });
  }

  addGroup(uuid: string) {
    if (uuid !== '') {
      this.router.navigate(['prize-money-add-edit', {uuid: uuid}]).then();
    } else {
      this.router.navigate(['prize-money-add-edit']).then();
    }
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }
}
