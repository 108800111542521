import {Component, EventEmitter, Output} from '@angular/core';
import {AuthService} from '../../providers/auth.service';
import {SessionService} from '../../providers/session.service';
import {User} from '../../models/user';
import {getDeepFromObject} from '@nebular/auth';
import {NbToastrService} from '@nebular/theme';

@Component({
  selector: 'ngx-app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss'],
})
export class ModalLoginComponent {
  showMessages: any = {};
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  rememberMe = false;
  @Output() user_logged_in$: EventEmitter<User> = new EventEmitter<User>();
  protected options = {};

  constructor(private authService: AuthService, private sessionService: SessionService,
              private toastService: NbToastrService) {
  }

  login() {
    this.authService.login(this.user.username, this.user.password)
      .then(result => {
        if (result) {
          // login successful
          this.sessionService.user$.subscribe(user => {
            this.user_logged_in$.emit(user);
            // localStorage.setItem('sms_default_horse_show',
            // '{"id":"6e86aedd-0bc9-4a79-b8dd-672f3f9cd5dc","number":136,"name":"#317958 2023 Kentucky Summer
            // Classic","start_date":"2023-08-01T00:00:00.000+00:00","end_date":"2023-08-06T00:00:00.000+00:00",
            // "horse_show_organizer_id":"402ab25b-459c-11e9-9841-1697cbd78800",
            // "venue_id":"69777b36-5248-11e9-9841-1697cbd78800","allow_online_reg":false,"message":null,
            // "message_type":null,"pinned":false,"country_iso_3":"USA","declaration_available":false,
            // "use_event_branding":false,"sms_alert_active":null,"sms_topic_arn":null,
            // "display_advance_schedule":true,"has_qualifying_sections":false,"rtd_enabled":false,
            // "horse_show_organizer_name":"Kentucky Horse Shows LLC","venue_name":"Kentucky Horse Park",
            // "venue_utc_offset":-4,"city":"Lexington","state":"KY","country":"USA"}');
            location.reload();
          });
        } else {
          // login failed
          this.toastService.danger('', 'Username or password is incorrect');
        }
      }).catch((error: any) => {
      this.toastService.danger('', 'Username or password is incorrect');
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
