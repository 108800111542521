<h1 id="title" class="title">Login</h1>
<p class="sub-title">Enter your user name and password to log in and begin working with events.</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-user">User Name:</label>
    <input nbInput
           fullWidth
           [(ngModel)]="user.username"
           #email="ngModel"
           name="user"
           id="input-user"
           placeholder="User"
           autofocus
           [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
           [required]="getConfigValue('forms.validation.email.required')"
           [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="error-message" *ngIf="email.errors?.required">
        User name is required!
      </p>
<!--      <p class="error-message" *ngIf="email.errors?.pattern">-->
<!--        Email should be the real one!-->
<!--      </p>-->
    </ng-container>
  </div>

  <div class="form-control-group">
    <label class="label" for="input-password">Password:</label>
    <input nbInput
           fullWidth
           [(ngModel)]="user.password"
           #password="ngModel"
           name="password"
           type="password"
           id="input-password"
           placeholder="Password"
           [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
           [required]="getConfigValue('forms.validation.password.required')"
           [minlength]="getConfigValue('forms.validation.password.minLength')"
           [maxlength]="getConfigValue('forms.validation.password.maxLength')"
           [attr.aria-invalid]="password.invalid && password.touched ? true : null">
    <ng-container *ngIf="password.invalid && password.touched ">
      <p class="error-message" *ngIf="password.errors?.required">
        Password is required!
      </p>
      <p class="error-message" *ngIf="password.errors?.minlength || password.errors?.maxlength">
        Password should contains
        from {{ getConfigValue('forms.validation.password.minLength') }}
        to {{ getConfigValue('forms.validation.password.maxLength') }}
        characters
      </p>
    </ng-container>
  </div>

  <div class="form-control-group accept-group">
    <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">Remember me</nb-checkbox>
    <a class="forgot-password" routerLink="../request-password">Forgot Password?</a>
  </div>

  <button nbButton
          fullWidth
          status="success"
          [disabled]="submitted || !form.valid"
          [class.btn-pulse]="submitted">
    Log In
  </button>
</form>

<!--&lt;!&ndash;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->

<!-- <div style="place-content: space-around;" data-bs-backdrop="static">-->
<!--    <div>-->
<!--      <fa-icon [icon]="faLock" role="icon"></fa-icon>-->
<!--      <strong style="margin-left: 20px">LOG IN</strong>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="modal-body" style="text-align: center">-->
<!--    <p class="card-text">Enter your user name and password to log in and begin working with events.</p>-->
<!--    <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>-->
<!--      <div class="form-group col-lg-6" [ngClass]="{ 'has-error': f.submitted && !username.valid }"-->
<!--           style="margin: auto">-->
<!--        <label for="nf-username" style="display: flex">USER NAME</label>-->
<!--        <input type="text" id="nf-username" name="nf-username" class="form-control" placeholder="Enter User Name.."-->
<!--               [(ngModel)]="model.username" #username="ngModel" required/>-->
<!--        <span class="help-block">Please enter your user name</span>-->
<!--      </div>-->
<!--      <div *ngIf="f.submitted && !username.valid" class="help-block text-danger">Username is required</div>-->
<!--      <div class="form-group col-lg-6" [ngClass]="{ 'has-error': f.submitted && !password.valid }"-->
<!--           style="margin: auto">-->
<!--        <label for="nf-password" style="display: flex">PASSWORD</label>-->
<!--        <input type="password" id="nf-password" name="nf-password" class="form-control"-->
<!--               placeholder="Enter Password.."-->
<!--               [(ngModel)]="model.password" #password="ngModel" required/>-->
<!--        <span class="help-block">Please enter your password</span>-->
<!--      </div>-->
<!--      <div *ngIf="f.submitted && !password.valid" class="help-block text-danger">Password is required</div>-->
<!--      <button type="submit" class="btn btn-sm btn-primary pull-right" style="margin: 40px">-->
<!--        <fa-icon [icon]='faDotCircle' role="icon"></fa-icon>-->
<!--        SUBMIT-->
<!--      </button>-->
<!--    </form>-->
<!--  </div>-->
