<nb-card [accent]="!edit ? 'info' : 'warning'">
  <nb-card-header>
    <h2 class="nb-card-title">{{ edit ? 'EDIT' : 'ADD' }} ORGANIZER RECORD</h2>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="organizationForm" (ngSubmit)="onSubmit()" class="arena-validation" novalidate>
      <div class="row">
        <div class="col-4 form-floating mb-3">
          <label>Organizer Number</label>
          <input nbInput fullWidth type="number" formControlName="number" id="number" name="number"
                 placeholder="Organizer Number" required>
        </div>
        <div class="col-8 form-floating mb-3">
          <label>Organizer Name</label>
          <input nbInput fullWidth type="text" formControlName="name" id="name" name="name"
                 placeholder="Organizer Name" required>
        </div>
      </div>

      <div class="form-floating mb-3">
        <label>Website URL</label>
        <input nbInput fullWidth type="text" formControlName="arena_width" id="url" name="arena_width"
               placeholder="Website URL" required>
      </div>

      <div class="row">
        <div class="col-6 form-floating mb-3">
          <label>Short Name</label>
          <input nbInput fullWidth type="number" formControlName="shortName" id="arena_width" name="arena_width"
                 placeholder="Short Name" required>
        </div>
        <div class="col-6 form-floating mb-3">
          <label>Payment Gateway</label>
          <nb-select fullWidth placeholder="Select Payment" formControlName="paymentGateway">
            <nb-option value="Braintree">Braintree</nb-option>
            <nb-option value="Bambora">Bambora</nb-option>
            <nb-option value="Stripe">Stripe</nb-option>
            <nb-option value="">None</nb-option>
          </nb-select>
        </div>
      </div>

      <div class="row">
        <div class="col-4 form-floating mb-3">
          <label>MERCHANT ACCOUNT ID</label>
          <input nbInput fullWidth type="text" formControlName="merchantAccountId" id="arena_lat" name="arena_lat"
                 placeholder="MERCHANT ACCOUNT ID" required>
        </div>
        <div class="col-4 mb-3" style="align-items: center;display: flex; padding-top: 20px;">
          <nb-checkbox status="basic" formControlName="collectConvenienceFee" style="padding-top: 20px">CHARGE
            CONVENIENCE FEE
          </nb-checkbox>
        </div>
        <div class="col-4 mb-3">
          <label>RATE (%)</label>
          <input nbInput fullWidth type="text" formControlName="convenienceFeeRate" id="arena_lat" name="arena_lat"
                 placeholder="RATE (%)" required>
        </div>
        <div class="col-12 mb-3">
          <label>CONVENIENCE FEE WORDING</label>
          <textarea rows="5" nbInput fullWidth formControlName="convenienceFeeWording" class="form-control"
                  name="federation_code"
                  placeholder="CONVENIENCE FEE WORDING"></textarea>
        </div>
      </div>
      <button nbButton shape="semi-round" status="primary" type="submit"
              class="btn-save">{{ edit ? 'Update' : 'Save' }}
      </button>
    </form>
  </nb-card-body>
</nb-card>
