import { Component } from '@angular/core';
import { Horse_show_organizer } from '../../../models/horse_show_organizer';
import { Person } from '../../../models/person';
import { faPlus } from '@fortawesome/sharp-light-svg-icons';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'ngx-app-horse-show-organizer',
  templateUrl: './horse-show-organizer.component.html',
  styleUrls: ['./horse-show-organizer.component.scss'],
})
export class HorseShowOrganizerComponent {

  faPlus = faPlus;

  constructor() { }


  organizer: Horse_show_organizer = new Horse_show_organizer();
  person: Person = new Person();
  searchingForPerson: boolean = false;
  edit: boolean = false;
  organizationForm: FormGroup = new FormGroup({
    number: new FormControl('', [Validators.required, Validators.minLength(1)]),
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    url: new FormControl('', [Validators.required, Validators.minLength(1)]),
    // shortName: new FormControl(''),
    shortName: new FormControl('', [Validators.required, Validators.minLength(1)]),
    paymentGateway: new FormControl('', [Validators.required, Validators.minLength(1)]),
    merchantAccountId: new FormControl('', [Validators.required, Validators.minLength(1)]),
    collectConvenienceFee: new FormControl('', [Validators.required, Validators.minLength(1)]),
    convenienceFeeRate: new FormControl('', [Validators.required, Validators.minLength(1)]),
    convenienceFeeWording: new FormControl('', [Validators.required, Validators.minLength(1)]),
  });

  onSubmit() {

  }
}
