import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Person } from '../models/person';
import { AppSettings } from '../app.settings';

@Injectable({
  providedIn: 'root',
})
export class PersonService {

  constructor(private http: HttpClient) { }

  getPersonSearchResults(search_term: string): Observable<Person[]> {

    if (search_term.length > 0) {
      return this.http.get(AppSettings.API_ENDPOINT + 'people/search?search_term=' +
          encodeURIComponent(search_term)).pipe(map((response: any) => {
        return response.data.included;

      }));
    } else {
      return new Observable<Person[]>();
    }

  }

  save(person: Person): Promise<Person> {
    return new Promise((resolve, reject) => {
      if (person.hasOwnProperty('id') && person.id) {
        return this.http.put(AppSettings.API_ENDPOINT + 'person', person).subscribe({
          next:
            (response: any) => {
              if (response.data && response.data.type === 'person') {
                resolve(response.data.attributes);
              } else {
                reject(response);
              }
            },
          error: (error_response) => {
            reject(error_response);
          },
          complete: () => { console.info('Person successfully updated.');
          },
        });

      } else {
        return this.http.post(AppSettings.API_ENDPOINT + 'person', person).subscribe({
          next:
            (response: any) => {
              if (response.data && response.data.type === 'person') {
                resolve(response.data.attributes);
              } else {
                reject(response);
              }

            },
          error: (error_response) => {
            reject(error_response);
          },
          complete: () => { console.info('Person successfully created.'); },
        });
      }

    });


  }

  update(id: string, changeMap: Map<string, any>): Promise<Person> {

    const changeMapObj = Object.fromEntries(changeMap);

    return new Promise((resolve, reject) => {

        return this.http.patch(AppSettings.API_ENDPOINT + 'person/' + id, changeMapObj).subscribe({
          next:
            (response: any) => {
              if (response.data && response.data.type === 'person') {
                resolve(response.data.attributes);
              } else {
                reject(response);
              }

            },
          error: (error_response) => {
            reject(error_response);
          },
          complete: () => { console.info('Person successfully updated.'); },
        });

    });

  }
}
