import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Horse_show_organizer} from '../models/horse_show_organizer';
import {AppSettings} from '../app.settings';

@Injectable({
    providedIn: 'root',
})
export class HorseShowOrganizerService {

    constructor(private http: HttpClient) {
    }

    get(id: string): Promise<Horse_show_organizer> {
        return new Promise((resolve, reject) => {
            return this.http.get(AppSettings.API_ENDPOINT + 'horse-show-organizers/horse-show-organizer/' + id)
                .subscribe((response: any) => {
                        if (response.data && response.data.type === 'horse_show_organizer') {
                            resolve(response.data.attributes);
                        } else {
                            reject(response);
                        }
                    },
                    error_response => {
                        if (error_response instanceof HttpErrorResponse) {
                            reject(error_response.error);
                        }
                        reject(error_response);
                    });
        });
    }


    getList(): Promise<Horse_show_organizer[]> {
        return new Promise((resolve, reject) => {

            return this.http.get(AppSettings.API_ENDPOINT + 'authorized-user/horse-show-organizer-list')
                .subscribe((response: any) => {
                        if (response.data && response.data.type === 'horse_show_organizer') {
                            resolve(response.data.included);
                        } else {
                            reject(response);
                        }
                    },
                    error_response => {
                        if (error_response instanceof HttpErrorResponse) {
                            reject(error_response.error);
                        }
                        reject(error_response);
                    });
        });

    }
}
