import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Person} from '../../models/person';
import {PersonService} from '../../providers/person.service';

@Component({
  selector: 'ngx-app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss'],
})
export class PersonComponent {

  constructor(private personService: PersonService) {
  }

  _person: Person = new Person();
  changeMap: Map<string, any> = new Map<string, any>();

  @Input() set person(person: Person) {
    this.setPerson(person);
  }

  @Output() person_saved$: EventEmitter<Person> = new EventEmitter<Person>();

  setPerson(person: Person) {
    this._person = person;
    this.changeMap.clear();
  }

  onSubmit() {
    if (!this._person.hasOwnProperty('id')) {
      this.personService.save(this._person).then((person: Person) => {
        this.setPerson(person);
        this.person_saved$.emit(person);
      }).catch((error) => {
        alert('Error saving person: ' + error);
      });
    } else {
      this.personService.update(this._person.id, this.changeMap).then((person: Person) => {
        this.setPerson(person);
        this.person_saved$.emit(person);
      }).catch((error) => {
        alert('Error updating person: ' + error);
      });
    }
  }

  handleChange(field_name: any, field_value: any) {
    this.changeMap.set(field_name, field_value);
  }

  handlePersonSelected(event: any) {
    this.setPerson(event);
  }
}
