import {Component} from '@angular/core';
import {HorseShowService} from '../../../../providers/horse-show.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NbToastrService} from '@nebular/theme';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'ngx-section-add-edit',
  templateUrl: './section-add-edit.component.html',
  styleUrls: ['./section-add-edit.component.scss'],
})
export class SectionAddEditComponent {
  sectionForm: FormGroup = new FormGroup({
    section_number: new FormControl('', [Validators.required, Validators.minLength(1)]),
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    discipline_code: new FormControl('', [Validators.required, Validators.minLength(1)]),
    entry_fee: new FormControl(''),
    fee_option: new FormControl('C', [Validators.required, Validators.minLength(1)]),
    usef_section_code: new FormControl(''),
  });
  edit: boolean = false;
  _discipline = [
    {name: 'Hunter', option: 'H'},
    {name: 'Jumper', option: 'J'},
    {name: 'Equitation', option: 'E'},
    {name: 'Dressage', option: 'D'},
    {name: 'Driving', option: 'A'},
    {name: 'Breed', option: 'B'},
    {name: 'Saddlebred', option: 'S'},
    {name: 'Hackney', option: 'K'},
    {name: 'Western', option: 'W'},
    {name: 'Other', option: 'O'},
  ];
  _competitions = [];
  horse_show: any = {};
  public editSection: any;

  constructor(private showService: HorseShowService, private route: ActivatedRoute,
              private toastService: NbToastrService, private router: Router) {
    this.horse_show = JSON.parse(localStorage.getItem('sms_default_horse_show'));
    this.route.params.subscribe(params => {
      if (params['uuid']) {
        this.getSection(params['uuid']);
        this.edit = true;
      }
    });
    this.checkFee('C');
  }

  onSubmit() {
    if (this.sectionForm.valid) {
      const body: any = {
        section_number: this.sectionForm.controls['section_number'].value,
        horse_show_id: this.horse_show.id,
        horse_show_number: this.horse_show.horse_show_number,
        name: this.sectionForm.controls['name'].value,
        discipline_code: this.sectionForm.controls['discipline_code'].value,
        entry_fee: this.sectionForm.controls['entry_fee'].value,
        fee_option: this.sectionForm.controls['fee_option'].value,
        competitions: [],
      };

      if (this.horse_show.federation_code) {
        body.usef_section_code = this.sectionForm.controls['usef_section_code'].value;
      }

      if (!this.edit) {
        this.showService.postSection(body).then(res => {
          this.toastService.success('', 'Section created');
          // set to edit and enable add competition
          this.editSection = res;
          this.edit = true;
        }, error => {
          this.toastService.danger('', 'Error creating section');
        });
      } else {
        body.id = this.editSection.id;
        this.showService.putSection(this.editSection.id, body).then(res => {
          this.toastService.success('', 'Section updated');
        }, error => {
          this.toastService.danger('', 'Error updating section');
        });
      }
    } else {
      this.toastService.warning('', 'Please check the form');
    }
  }

  private getSection(id: any) {
    this.showService.getSection(id).then(res => {
      this.editSection = res;
      this.getCompetitionsBySection();
      this.sectionForm.controls['name'].setValue(res.name);
      this.sectionForm.controls['section_number'].setValue(res.section_number);
      this.sectionForm.controls['discipline_code'].setValue(res.discipline_code);
      this.sectionForm.controls['entry_fee'].setValue(res.entry_fee);
      this.sectionForm.controls['fee_option'].setValue(res.fee_option);
    });
  }

  addCompetition() {
    localStorage.setItem('section', JSON.stringify(this.editSection));
    this.router.navigate(['competition-add-edit', {section: true}]).then();
  }

  getCompetitionsBySection() {
    this.showService.getSectionCompetitions(this.editSection.section_number).then(competitions => {
      this._competitions = [];
      competitions.forEach((el: any) => {
        el.country = el.country_iso_alpha_3;
        Object.keys(el).forEach(key => {
          if (typeof el[key] === 'boolean') {
            el[key] = el[key].toString();
          }
        });
        this._competitions.push(el);
      });
    });
  }

  checkFee(value) {
    if (value === 'C') {
      this.sectionForm.get('entry_fee').disable();
    } else {
      this.sectionForm.get('entry_fee').enable();
    }
  }
}

